import React, { ReactElement } from 'react';
import './Rules.scss';

const Lt: React.FC = (): ReactElement => {
  return (
    <div className="Lt">
      <ol>
        <li>
          Vartotojų kampanija „Lay’s skoniai 2025“ yra Lietuvoje vykdoma
          kampanija, kurią organizuoja „Lay’s“ traškučių platintojas Lietuvoje –
          UAB „Lithuanian Snacks“ (toliau – „kampanijos organizatorius“), įmonės
          kodas 111562753, Panerių g. 37, LT-03209, Vilnius. Loteriją
          organizuoja UAB „Sorbum LT“, įmonės kodas 125767647, J. Jasinskio g.
          16 G, LT-03163 Vilnius.
        </li>
        <li>Kampanijos teritorija – Lietuvos Respublika.</li>
        <li>
          Kampanijoje gali dalyvauti įvairaus amžiaus Lietuvos gyventojai.
        </li>
        <li>
          Kampanija vykdoma nuo 2025 m. sausio 20 d. iki balandžio 13 d.
          (imtinai).
        </li>
        <li>
          Norėdami dalyvauti kampanijoje, turite balsuoti už mėgstamiausią skonį
          ir užsiregistruoti svetainėje www.lays.lt.{' '}
          <ol>
            <li>Balsuoti galite vieną kartą.</li>
            <li>
              Registruojantis būtina nurodyti loterijos dalyvio vardą, pavardę,
              telefono numerį ir el. pašto adresą.
            </li>
            <li>
              Kampanijos dalyvis atsako už teisingų asmens duomenų pateikimą ir
              už klaidas, padarytas juos pateikiant.
            </li>
            <li>Registracija interneto svetainėje www.lays.lt yra nemokama.</li>
          </ol>
        </li>
        <li>
          <span className="bold">Prizinis fondas ir lošimų datos</span>
          <ol>
            <li>Prizinio fondo vertė yra 2760 eurai.</li>
            <li>
              Kampanijos organizatorius išrinks 60 (šešiasdešimt) dėžių „Lay's“
              su 120 g / 130 g dydžio pakuotėmis traškučių laimėtojų (dėžės
              kaina 46 eurų). Laimėtojas kaip prizą gaus skonio, už kurį jis
              balsavo, „Lay's“ bulvių traškučius. Vienoje dėžėje yra 21 pakuotė
              po 130 g „Lay's Roasted Garlic & Herbs“ arba „Lay's Mac & Cheese“
              arba 21 pakuotė po 120 g „Lay's MAX Pulled Beef“. Kiekvienas
              laimėtojas kaip prizą gaus vieną dėžę arba 21 pakuotę.
            </li>
            <li className="bold">
              Burtų traukimas vyks nuo 2025 m. sausio 27 d. iki 2025 m.
              balandžio 14 d. kiekvieną pirmadienį 12.00 val.. Bus galima
              laimėti penkias „Lay's“ traškučių dėžes. Burtų traukime dalyvaus
              visi iki to laiko užsiregistravusieji. Laimėti galima vieną kartą.
            </li>
          </ol>
        </li>
        <li>
          Kampanijos dalyvių registraciją ir prizų traukimą burtų keliu
          užtikrins akcijos organizatoriaus UAB „Sorbum LT“ įgaliotasis
          atstovas, kuris taip pat susisieks su laimėtojais.
        </li>
        <li>
          <span className="bold">Asmens duomenų tvarkymo tvarka</span>
          <ol>
            <li>
              Asmens duomenys, kuriuos dalyvis teikia per kampaniją, yra saugomi
              ir tvarkomi UAB „Sorbum LT“ kampanijos tikslais, vadovaujantis
              Lietuvos Respublikos įstatymais.
            </li>
            <li>
              Kampanijos dalyvis sutinka, kad UAB „Sorbum LT“ tvarkytų pateiktus
              duomenis šiais tikslais:{' '}
              <ol>
                <li>apibendrinti kampanijos rezultatus; </li>
                <li>perduoti informaciją UAB „Sorbum LT“ partneriams; </li>
                <li>išaiškinti loterijos laimėtojus ir juos informuoti;</li>
                <li>
                  skelbti laimėtojo vardą ir pavardę kampanijos interneto
                  svetainėje ir socialiniuose tinkluose.
                </li>
              </ol>
            </li>
            <li>
              Kampanijos dalyviai turi teisę gauti papildomą informaciją apie
              UAB „Sorbum LT“ tvarkomus asmens duomenis. Norėdami gauti
              informacijos, rašykite adresu:{' '}
              <a href="mailto:infoLT@pepsico.com">infoLT@pepsico.com</a>
            </li>
            <li>
              Asmens duomenų valdytojas yra UAB „Lithuanian Snacks“,
              registracijos numeris 111562753, adresas: Panerių g. 37, LT-03209,
              Vilnius,{' '}
              <a href="mailto:infoLT@pepsico.com">infoLT@pepsico.com</a>.{' '}
            </li>
            <li>
              Surinkti duomenys yra saugomi 1 (vieną) mėnesį nuo kampanijos
              pabaigos datos arba iki 2025-05-14.
            </li>
          </ol>
        </li>
        <li>
          Laimėtojai skelbiami ne vėliau nei iki 6.3 punkte nurodytų datų darbo
          dienos pabaigos interneto svetainėje{' '}
          <a href="http://www.lays.lt">www.lays.lt</a>.
        </li>
        <li>
          Laimėtojų vardai ir pavardės interneto svetainėje bus prieinami 2
          (dvi) savaites pasibaigus kampanijai, t. y. iki 2025-04-27.
        </li>
        <li>
          Su laimėtojais susisieks UAB „Sorbum LT“ atstovas registracijos
          formoje nurodytu el. pašto adresu arba telefonu ir susitars dėl prizo
          atsiėmimo laiko.
        </li>
        <li>
          Laimėtas traškučių dėžes galima atsiimti UAB „Sorbum LT“ biure
          (J. Jasinskio g. 16 G, LT-03163 Vilnius) arba susitarus kitu būdu
          (pvz., pasinaudojus kurjerio paslauga ar paštomatu).{' '}
          <ol>
            <li>
              Jeigu laimėtojas nepateikia asmens tapatybę patvirtinančio
              dokumento arba jeigu dokumentuose nurodyti duomenys skiriasi nuo
              registracijos formoje nurodytų, laimėtojas neturi teisės gauti
              prizo.
            </li>
            <li>
              Jei laimėtojas nėra pilnametis, jo prizą gali atsiimti jo atstovas
              pagal įstatymą, pateikdamas laimėtojo ir savo asmens tapatybę
              patvirtinantį dokumentą (pasą, asmens tapatybės kortelę,
              vairuotojo pažymėjimą).{' '}
            </li>
          </ol>
        </li>
        <li>
          Perduodant prizą, laimėtojas ir kampanijos organizatoriaus įgaliotasis
          atstovas UAB „Sorbum LT“ pasirašo prizo perdavimo–priėmimo aktą.
          Pasirašęs aktą laimėtojas neturi teisės kampanijos organizatoriams
          pareikšti reikalavimų arba skundų.
        </li>
        <li>
          Kampanijos organizatoriai neatsako už bet kokią žalą, atsiradusią
          naudojant prizą.
        </li>
        <li>Prizai nekeičiami į kitus prizus ar grynuosius pinigus.</li>
        <li>
          Su prizo atsiėmimu susijusios papildomos išlaidos, kurioms netaikomas
          šios taisyklės, laimėtojui nekompensuojamos.
        </li>
        <li className="bold">
          Neatiduotas laimėtojui arba neatsiimtas laimėtojo prizas lošiamas iš
          naujo. Jeigu su prizo laimėtoju nepasiseka susisiekti per 5 darbo
          dienas, renkamas naujas laimėtojas.{' '}
        </li>
        <li>
          Kampanijos organizatorius neatsako, jeigu kampanijos dalyviai
          nesilaiko šių taisyklių.
        </li>
        <li>
          Kampanijos organizatorius neatsako, jeigu kampanijos dalyvis
          pašalinamas iš kampanijos ir prizas neperduodamas dėl kampanijos
          taisyklių nesilaikymo, jeigu kampanijos dalyvio pateikti duomenys yra
          klaidingi arba netikslūs, arba jeigu su dalyviu nepavyksta susisiekti
          dėl organizatoriams nepriklausančių priežasčių.
        </li>
        <li>
          Pasireiškus nenugalimos jėgos aplinkybei, kampanijos organizatorius
          turi teisę kampaniją nutraukti vienašališkai. Tokiu atveju kampanijos
          organizatorius privalo informuoti Lietuvos vartotojų teisių apsaugos
          tarnybą ir žiniasklaidoje paskelbti informaciją apie kampanijos
          sustabdymą.
        </li>
        <li>
          Visos su kampanijos organizavimu ir (arba) vykdymu susijusios
          pretenzijos ir skundai iki 2025 m. gegužės 14 d. turi būti pateikti
          raštu organizatoriui UAB „Sorbum LT“ adresu J. Jasinskio g. 16 G,
          LT-03163 Vilnius arba el. paštu win@sorbum.eu, su raktažodžiu „Lay’s
          skoniai“, pridedant pagrįstą skundą ir skundo dokumentų priedus arba
          jų kopijas.{' '}
          <ol>
            <li>Vėliau nei 2025-05-14 pateikti skundai nenagrinėjami.</li>
            <li>
              Kampanijos organizatorius į pateiktą skundą atsako raštu per 15
              dienų nuo jo gavimo. Jeigu kampanijos organizatorius mano, kad
              skundas yra pagrįstas, jis skundo pareiškėją informuoja apie
              skundo patenkinimo eigą ir datą. Jei skundo pateikėjas yra
              nepatenkintas gautu atsakymu, jis turi teisę įstatymo nustatyta
              tvarka ginti savo teises ir interesus.
            </li>
            <li>Skundo pateikimo data yra laikoma jo išsiuntimo data.</li>
            <li>Skunde būtina nurodyti lošimo dalyvio vardą ir pavardę.</li>
          </ol>
        </li>
        <li>
          Šiose taisyklėse yra nurodytos kampanijos organizatoriaus, jo atstovų
          ir dalyvių teisės bei pareigos.
        </li>
        <li>
          Kampanijos organizatorius įspėja, kad reklaminėje medžiagoje pateikta
          informacija yra tik informacinio pobūdžio.
        </li>
        <li>
          Kampanijos organizatorius tvarko duomenis pagal Europos asmens duomenų
          apsaugos reglamento nuostatas.{' '}
        </li>

        <li>
          Kampanijoje negali dalyvauti UAB „Lithuanian Snacks“ ir UAB „Sorbum
          LT“ darbuotojai ir jų šeimos nariai. Daugiau informacijos:{' '}
          <a
            href="https://pepsicoprivacypolicy.com/lt?domain=pepsico.lt"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            https://pepsicoprivacypolicy.com/lt?domain=pepsico.lt
          </a>
        </li>
      </ol>
    </div>
  );
};

export default Lt;
