import React, { ReactElement } from 'react';
import './Rules.scss';

const Et: React.FC = (): ReactElement => {
  return (
    <div className="Et">
      <ol>
        <li>
          Tarbijakampaania „Lay’s maitsed“ on Eestis toimuv kampaania, mida
          korraldab Lay’s krõpsude edasimüüja Eestis – PepsiCo Eesti AS
          (edaspidi kampaania korraldaja), registrinumber 10438708, Tuglase 14,
          51006 Tartu. Loosimist korraldab Direct Messenger OÜ, registrinumber
          11981389, Türi tn 10C, Tallinn 11313.
        </li>
        <li>Kampaania territoorium – Eesti Vabariik.</li>
        <li>Kampaanias võivad osaleda igas vanuses Eesti elanikud.</li>
        <li>
          Kampaania leiab aset 20. jaanuarist kuni 13. aprillini 2025 (k.a).
        </li>
        <li>
          Kampaanias osalemiseks tuleb www.lays.ee kodulehel anda hääl oma
          lemmikmaitsele ja end registreerida.{' '}
          <ol>
            <li>Hääletada saab ühe korra.</li>
            <li>
              Registreerimisel tuleb ära märkida loosis osaleja eesnimi,
              perekonnanimi, telefoninumber ja e-posti aadress.{' '}
            </li>
            <li>
              Kampaanias osaleja vastutab korrektsete isikuandmete esitamise ja
              nende esitamisel tehtud vigade eest.{' '}
            </li>
            <li>Registreerimine veebilehel www.lays.ee on tasuta.</li>
          </ol>
        </li>
        <li>
          <span className="bold">Auhinnafond ja loosimised</span>
          <ol>
            <li>Auhinnafondi koguväärtus on 3138 €.</li>
            <li>
              Kampaania korraldaja loosib välja 60 (kuuskümmend) kasti Lay’s
              120g/130g pakisuuruses krõpse (väärtuses 52,3 €/kast). Auhinnaks
              saab võitja just selle maitsega Lay`s kartulikrõpsud, mille poolt
              ta hääletas. Ühes kastis on 21 pakki Lay’s Roasted garlic & herbs
              või Lays Mac & Cheese 130g tooteid või 21 pakki Lays MAX Pulled
              beef 120g tooteid. Iga võitja saab auhinnaks ühe kasti ehk 21
              pakki.
            </li>
            <li className="bold">
              Loosimised toimuvad igal esmaspäeval alates 27. jaanuarist kuni
              14. aprillini 2025. a. kell 12:00, mil loositakse välja viis kasti
              Lay’s krõpsu. Loosis osalevad kõik selleks hetkeks registreerunud.
              Võita saab ühe korra.
            </li>
          </ol>
        </li>
        <li>
          Kampaanias osalejate registreerimise ja auhindade loosimise tagab
          kampaania korraldaja volitatud esindaja Direct Messenger OÜ-st, kes
          võtab ka võitjatega ühendust.
        </li>
        <li>
          <span className="bold">Isiklike andmete käsitlemine</span>
          <ol>
            <li>
              Kampaania käigus osaleja poolt esitatud isikuandmed on kaitstud ja
              PepsiCo Eesti AS töötleb neid vastavalt Eesti Vabariigis
              kehtivatele seadustele kampaania läbiviimise eesmärgil.
            </li>
            <li>
              Kampaanias osaleja annab PepsiCo Eesti AS-le nõusoleku töödelda
              esitatud andmeid järgnevatel eesmärkidel:{' '}
              <ol>
                <li>kampaania tulemuste kohta kokkuvõtete tegemiseks; </li>
                <li>
                  PepsiCo Eesti AS poolt kampaaniaga seotud koostööpartneritele
                  info edastamiseks;{' '}
                </li>
                <li>võitjate loosimiseks ja nende teavitamiseks;</li>
                <li>
                  võitja nime avaldamiseks kampaania veebilehel ja
                  sotsiaalmeedias.
                </li>
              </ol>
            </li>
            <li>
              Kampaanias osalejatel on õigus saada lisainfot PepsiCo Eesti AS
              poolt isikuandmete töötlemise kohta. Selleks tuleb saata e-kiri
              aadressile:{' '}
              <a href="mailto:pepsico.eesti@pepsico.com">
                pepsico.eesti@pepsico.com
              </a>
            </li>
            <li>
              Isikuandmete vastutav töötleja on PepsiCo Eesti AS, registrikood
              10438708, Tuglase 14, 51006 Tartu, Eesti, tel 738 6600,{' '}
              <a href="mailto:pepsico.eesti@pepsico.com">
                pepsico.eesti@pepsico.com
              </a>
            </li>
            <li>
              Kogutud andmeid säilitatakse 1 (üks) kuu alates kampaania
              lõpukuupäevast ehk kuni 14.05.2025.
            </li>
          </ol>
        </li>
        <li>
          Võitjate nimed avalikustatakse hiljemalt punktis 6.3. mainitud
          kuupäevade tööpäeva lõpuks veebilehel{' '}
          <a href="http://www.lays.ee">www.lays.ee</a>.{' '}
        </li>
        <li>
          Võitjate nimed on veebilehel üleval 2 (kaks) nädalat peale kampaania
          lõppu ehk kuni 27.04.2025.
        </li>
        <li>
          Võitjatega võtab ühendust Direct Messenger OÜ esindaja
          registreerimisvormis märgitud e-posti või telefoni teel, et leppida
          kokku auhinna üleandmise aeg.
        </li>
        <li>
          Võidetud krõpsukastid saab kätte Direct Messenger OÜ kontorist (Türi
          tn 10C, Tallinn) või kokkuleppel muul viisil (nt kuller või
          pakiautomaat). Auhinna kättesaamiseks peab võitja esitama oma isikut
          tõendava dokumendi (pass, ID-kaart, juhiluba).{' '}
          <ol>
            <li>
              Kui auhinna võitja ei esita isikut tõendavat dokumenti või kui
              dokumentidel toodud andmed erinevad registreerimisvormil toodust,
              ei ole võitjal õigust auhinda saada.
            </li>
            <li>
              Kui auhinna võitja ei ole veel täisealine, võib tema auhinna vastu
              võtta tema seaduslik esindaja, esitades võitja ja tema enda isikut
              tõendava dokumendi (pass, ID-kaart, juhiluba).
            </li>
          </ol>
        </li>
        <li>
          Auhinna üleandmisel allkirjastavad võitja ja kampaania korraldaja
          volitatud Direct Messenger OÜ esindaja auhinna üleandmise-vastuvõtmise
          akti. Pärast akti allkirjastamist ei ole võitjal õigust kampaania
          korraldajatele auhinna suhtes nõudeid ega kaebusi esitada.
        </li>
        <li>
          Kampaania korraldajad ei vastuta auhinna võitjale auhinna kasutamise
          käigus tekkinud kahju eest.
        </li>
        <li>Auhinda ei saa vahetada teise auhinna ega raha vastu.</li>
        <li>
          Auhinna kättesaamisega seotud lisakulusid, mida ei ole käesolevates
          eeskirjades reguleeritud, võitjale ei kompenseerita.
        </li>
        <li className="bold">
          Auhind, mida võitjale välja ei anta või mille võitja jätab välja
          võtmata, loositakse uuesti välja. Kui auhinna võitjaga ei saada
          kontakti 5 tööpäeva jooksul, loositakse uus võitja.{' '}
        </li>
        <li>
          Kampaania korraldaja ei kanna vastutust selle eest, kui kampaanias
          osalejad käesolevaid eeskirju ei järgi.
        </li>
        <li>
          Kampaania korraldaja ei kanna vastutust selle eest, kui kampaanias
          osaleja jääb loosimisest välja, kui auhinda eeskirjade mittejärgimise
          tõttu välja ei anta, kui kampaanias osaleja esitatud andmed on väärad
          või ebatäpsed või kui võitjatega ei ole võimalik ühendust saada
          kampaania korraldajatest mittesõltuvatel põhjustel.
        </li>
        <li>
          Vääramatu jõu asjaolude esinemisel on kampaania korraldajal õigus
          kampaania ühepoolselt katkestada. Sellisel juhul on kampaania
          korraldajal kohustus teavitada Eesti Tarbijakaitseametit ja edastada
          teade kampaania peatamisest ka massimeedia kaudu.
        </li>
        <li>
          Kõik nõuded ja kaebused seoses kampaania korralduse ja/või
          läbiviimisega tuleb esitada kirjalikult kampaania korraldajale PepsiCo
          Eesti AS-le 14. maiks 2025 aadressil Tuglase 14, Tartu 51006 või
          e-kirja teel aadressile pepsico.eesti@pepsico.com, märkides märksõnaks
          „Lay’s maitsed“ ja esitades oma põhjendatud kaebuse ning lisades
          kaebuses viidatud dokumendid või nende koopiad.{' '}
          <ol>
            <li>
              Kaebusi, mis esitatakse pärast 14.05.2025, ei võeta menetlusse.
            </li>
            <li>
              Kampaania korraldaja vastab esitatud kaebusele kirjalikult 15
              päeva jooksul pärast selle kättesaamist. Kui kampaania korraldaja
              peab kaebust õigustatuks, teavitavad nad kaebuse esitajat kaebuse
              rahuldamise protsessist ja kuupäevast. Kui kaebuse esitaja ei ole
              saadud vastusega rahul, on tal õigus seadusega ettenähtud korras
              oma õigusi ja huve kaitsta.
            </li>
            <li>
              Kaebuse esitamise kuupäevaks loetakse selle postitamise kuupäeva.
            </li>
            <li>
              Kaebusele tuleb alati lisada loosis osaleja eesnimi ja
              perekonnanimi.
            </li>
          </ol>
        </li>
        <li>
          Käesolevates eeskirjades on sätestatud nii kampaania korraldaja, tema
          esindajate kui osalejate õigused ja kohustused.
        </li>
        <li>
          Kampaania korraldaja hoiatab, et reklaammaterjalides toodud teave
          kannab ainult teavitavat eesmärki.
        </li>
        <li>
          Kampaania korraldaja töötleb andmeid vastavalt Euroopa isikuandmete
          kaitse üldmäärusele.{' '}
        </li>
        <li>
          Kampaanias ei tohi osaleda PepsiCo Eesti AS, Direct Messenger OÜ ning
          Trickster Studios OÜ töötajad ja perekonnaliikmed. Lähemalt:{' '}
          <a
            href="https://pepsicoprivacypolicy.com/et?domain=pepsico.ee"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            https://pepsicoprivacypolicy.com/et?domain=pepsico.ee
          </a>
        </li>
      </ol>
    </div>
  );
};

export default Et;
