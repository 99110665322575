import React, { ReactElement } from 'react';
import './Rules.scss';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { sentenceCase } from 'converting-case';

//Component imports
import LogoLink from '../../components/logo-link/LogoLink';

//Asset imports
import Chips1 from '../../assets/images/chips_4.png';
import Chips2 from '../../assets/images/chips_5.png';

// Partial imports
import Et from './Et';
import Lv from './Lv';
import Lt from './Lt';

const Rules: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  let RuleSet = Et;
  switch (getLanguage()) {
    case 'et':
      RuleSet = Et;
      break;
    case 'lv':
      RuleSet = Lv;
      break;
    case 'lt':
      RuleSet = Lt;
      break;
    default:
      RuleSet = Et;
      break;
  }
  return (
    <div className="Rules">
      <LogoLink />
      <h2>{sentenceCase(t('rules'))}</h2>
      <RuleSet />
    </div>
  );
};

export default Rules;
