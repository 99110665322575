import React, { ReactElement } from 'react';
import './LogoLink.scss';
import { Link } from 'react-router-dom';

//Asset imports
import Logo from '../../assets/images/logo.png';

const LogoLink: React.FC = (): ReactElement => {
  return (
    <Link to="/" id="main-logo">
      <img src={Logo} alt="Lays logo" />
    </Link>
  );
};

export default LogoLink;
