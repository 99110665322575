import React, { ReactElement } from 'react';
import './Thanks.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

//Component imports
import LogoLink from '../../components/logo-link/LogoLink';

//Asset imports

const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

const Thanks: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className={'Thanks ' + getLanguage()}>
      <LogoLink />
      <h2>{t('thank you').toUpperCase()}!</h2>
      <h3>{t('you have been registered')}!</h3>
      {/* <span>{t('PS! Keep your pack until the end of the campaign')}</span> */}
      <Link to="/" className="btn black">
        {t('back home')}
      </Link>
    </div>
  );
};

export default Thanks;
