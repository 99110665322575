import React, { ReactElement, useEffect, useState } from 'react';
import './Winners.scss';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import axios from 'axios';
import _ from 'lodash';
import { sentenceCase } from 'converting-case';

//Component imports
import LogoLink from '../../components/logo-link/LogoLink';

//Asset imports

interface PrizeList {
  box_of_lays: Pick<any, string | number | symbol>[];
}

const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

const Winners: React.FC = (): ReactElement => {
  const getWinners = async (lang: string) => {
    /* Uncomment the line below when pushing to live environment */
    const res = await axios.get('https://lays.ee.messenger.ee/api/dataservice/winners?lang=' + lang);
    /* Uncomment the line below for local testing/development */ 
    //const res = await axios.get('/api/dataservice/winners?lang=' + lang);
    const prizes = _.mapValues(_.groupBy(res.data, 'prize'), (plist) =>
      plist.map((prize) => _.omit(prize, 'prize'))
    );

    setWinners({
      box_of_lays: prizes.box_of_Lays === undefined ? [] : prizes.box_of_Lays,
    });
  };

  const { t } = useTranslation();

  const [winners, setWinners] = useState<PrizeList>({
    box_of_lays: [],
  });

  useEffect(() => {
    getWinners(getLanguage());
  }, []);

  return (
    <div className="Winners">
      <LogoLink />
      {/* <div className="circles-wrapper">
        <img className="circles" src={Circles} alt="yellow circles" />
      </div> */}

      <div className="winners-header">
        <h2>{sentenceCase(t('winners'))}</h2>
        <span>{t('We will contact winners in 5 weekdays')}</span>
      </div>
      <div className="winners-content">
        <div className="columns is-centered">
          <div className="column is-narrow">
            <ul>
              <li className="prize-name">{t('Box of lays').toUpperCase()}</li>
              <div className="winner-names">
                {winners.box_of_lays.map(function (item: any, index: any) {
                  return (
                    <li key={index}>
                      {item.first_name + ' ' + item.last_name}
                    </li>
                  );
                })}
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Winners;
