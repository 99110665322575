import React, { FormEvent, ReactElement, useEffect, useState } from 'react';
import './Register.scss';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { sentenceCase } from 'converting-case';
import _ from 'lodash';

// Component imports
import Loading from '../../components/loading/Loading';
import LogoLink from '../../components/logo-link/LogoLink';

// Asset imports
import SloganMobileEt from '../../assets/images/slogan_mobile_et.png';
import SloganMobileLv from '../../assets/images/slogan_mobile_lv.png';
import SloganMobileLt from '../../assets/images/slogan_mobile_lt.png';
import SloganEt from '../../assets/images/slogan_et.png';
import SloganLv from '../../assets/images/slogan_lv.png';
import SloganLt from '../../assets/images/slogan_lt.png';
import Products from '../../assets/images/products.png';
import ProductsMobile from '../../assets/images/products_mobile.png';

const Register: React.FC = (): ReactElement => {
  type ValidationErrors = {
    First_Name: { state: boolean; message: string };
    Last_Name: { state: boolean; message: string };
    Email: { state: boolean; message: string };
    Phone: { state: boolean; message: string };
    Code: { state: boolean; message: string };
    Privacy: { state: boolean; message: string };
  };

  type VoteType = 'none' | 'meat' | 'garlic' | 'cheese';

  type SloganType =
    | typeof SloganEt
    | typeof SloganLv
    | typeof SloganLt
    | typeof SloganMobileEt;

  const [SloganMobile, setSloganMobile] = useState<SloganType>(SloganMobileEt);

  const [Slogan, setSlogan] = useState<SloganType>(SloganEt);

  const { t } = useTranslation();

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  const [VoteValue, setVoteValue] = useState<VoteType>('none');

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(function () {
      switch (getLanguage()) {
        case 'et':
          setSloganMobile(SloganMobileEt);
          setSlogan(SloganEt);

          break;
        case 'lv':
          setSloganMobile(SloganMobileLv);
          setSlogan(SloganLv);

          break;
        case 'lt':
          setSloganMobile(SloganMobileLt);
          setSlogan(SloganLt);

          break;
        default:
          setSloganMobile(SloganMobileEt);
          setSlogan(SloganEt);

          break;
      }
    }, 100);
  }, []);

  const [loading, setLoading] = useState<boolean>(false);

  const [errors, setValidationErrors] = useState<ValidationErrors>({
    First_Name: { state: false, message: '' },
    Last_Name: { state: false, message: '' },
    Email: { state: false, message: '' },
    Phone: { state: false, message: '' },
    Code: { state: false, message: '' },
    Privacy: { state: false, message: '' },
  });

  const submitForm = (event: FormEvent) => {
    event.preventDefault();
    // console.log('campaign is over');
    setLoading(true);
    const myForm = document.getElementById('register-form') as HTMLFormElement;
    const formData = new FormData(myForm);
    // console.log(formData);
    formData.append('lang', getLanguage());
    // formData.forEach((entries) => console.log(entries));
    const check = document.getElementById('rules-check') as HTMLInputElement;
    if (check.checked) {
      axios
        /* Uncomment the line below when building/pushing to live environment */
        .post('https://lays.ee.messenger.ee/api/submit/new', formData) /*
        /* Uncomment the line below for local testing/development */ 
        //.post('/api/submit/new', formData)
        .then((res) => {
          console.log(res.data);

          setLoading(false);
          if (res.data.IsValid) {
            navigate('/thanks');
          } else {
            // console.log(res.data.Errors);
            setValidationErrors({
              First_Name: { state: false, message: '' },
              Last_Name: { state: false, message: '' },
              Email: { state: false, message: '' },
              Phone: { state: false, message: '' },
              Code: { state: false, message: '' },
              Privacy: { state: false, message: '' },
            });

            if (!_.isEmpty(res.data.Errors)) {
              const err = res.data.Errors;
              // console.log(err.code);
              setValidationErrors({
                Code: _.isEmpty(err.code)
                  ? { state: false, message: '' }
                  : { state: true, message: err.code[0].message },

                Email: _.isEmpty(err.email)
                  ? { state: false, message: '' }
                  : { state: true, message: err.email[0].message },
                Phone: _.isEmpty(err.phone)
                  ? { state: false, message: '' }
                  : { state: true, message: err.phone[0].message },

                First_Name: _.isEmpty(err.first_name)
                  ? { state: false, message: '' }
                  : { state: true, message: err.first_name[0].message },
                Last_Name: _.isEmpty(err.last_name)
                  ? { state: false, message: '' }
                  : { state: true, message: err.last_name[0].message },
                Privacy: _.isEmpty(err.privacy)
                  ? { state: false, message: '' }
                  : { state: true, message: err.privacy[0].message },
              });
              // console.log(response.data.errors);
            }
          }
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
    } else {
      setValidationErrors((prevState) => ({
        ...prevState,
        Privacy: { state: true, message: 'privacy' },
      }));
      setLoading(false);
      console.log('rules not checked');
    }
  };

  let CampaignOver = false;
  switch (getLanguage()) {
    case 'et':
      CampaignOver = false;
      break;
    case 'lv':
      CampaignOver = false;
      break;
    case 'lt':
      CampaignOver = false;
      break;
    default:
      CampaignOver = false;
      break;
  }

  return (
    <div className={'Register ' + getLanguage()}>
      {/* Absolutely poistioned elements */}
      <Loading loading={loading} />
      <LogoLink />

      <div className="home-wrapper">
        <div className="slogan">
          <img
            className="hidden-mobile"
            src={Slogan}
            alt="Taste, Vote and Win"
          />
          <img
            className="hidden-desktop"
            src={SloganMobile}
            alt="Taste, Vote and Win"
          />
        </div>
        <div className="vote-block">
          <div className="vote-products">
            <img className="hidden-mobile" src={Products} alt="Lays products" />
          </div>
          <div className="columns vote-buttons">
            <img
              className="hidden-desktop"
              src={ProductsMobile}
              alt="Lays products"
            />
            <div className="column left">
              <button
                className={
                  VoteValue === 'meat' ? 'vote-btn selected' : 'vote-btn'
                }
                onClick={() => setVoteValue('meat')}
              >
                {t('vote')}
              </button>
            </div>
            <div className="column center">
              <button
                className={
                  VoteValue === 'garlic' ? 'vote-btn selected' : 'vote-btn'
                }
                onClick={() => setVoteValue('garlic')}
              >
                {t('vote')}
              </button>
            </div>
            <div className="column right">
              <button
                className={
                  VoteValue === 'cheese' ? 'vote-btn selected' : 'vote-btn'
                }
                onClick={() => setVoteValue('cheese')}
              >
                {t('vote')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden-desktop invisible-spacer-div"></div>
      {/* <img
        src={PrizeMobileImg}
        className="mobile-prizes hidden-desktop"
        alt="sony tv and a box of lays"
      /> */}

      <div className="register-header">
        <h2>{t('enter your information')}</h2>
        <span>{t('All fields are required')}</span>
      </div>
      <form onSubmit={submitForm} id="register-form">
        <div className="field">
          <div className="custom-select">
            <select
              className=""
              value={VoteValue}
              onChange={(e) => setVoteValue(e.target.value as VoteType)}
              name="code"
            >
              <option value="none">{t('select default')}</option>
              <option value="meat">{t('select flavor1')}</option>
              <option value="garlic">{t('select flavor2')}</option>
              <option value="cheese">{t('select flavor3')}</option>
            </select>
          </div>
          {errors.Code.state && (
            <p className="help is-danger">
              {t('validation.' + errors.Code.message)}
            </p>
          )}
        </div>
        <div className="field">
          <div className="control">
            <input
              className="input"
              type="text"
              name="first_name"
              placeholder={sentenceCase(t('first name'))}
            />
          </div>
          {errors.First_Name.state && (
            <p className="help is-danger">
              {t('validation.' + errors.First_Name.message)}
            </p>
          )}
        </div>
        <div className="field">
          <div className="control">
            <input
              className="input"
              type="text"
              name="last_name"
              placeholder={sentenceCase(t('last name'))}
            />
          </div>
          {errors.Last_Name.state && (
            <p className="help is-danger">
              {t('validation.' + errors.Last_Name.message)}
            </p>
          )}
        </div>
        <div className="field">
          <div className="control">
            <input
              className="input"
              type="text"
              name="email"
              placeholder={sentenceCase(t('email'))}
            />
          </div>
          {errors.Email.state && (
            <p className="help is-danger">
              {t('validation.' + errors.Email.message)}
            </p>
          )}
        </div>
        <div className="field">
          <div className="control">
            <input
              className="input"
              type="text"
              name="phone"
              placeholder={sentenceCase(t('phone'))}
            />
          </div>
          {errors.Phone.state && (
            <p className="help is-danger">
              {t('validation.' + errors.Phone.message)}
            </p>
          )}
        </div>

        <label className="checkbox">
          <input type="checkbox" id="rules-check" name="privacy" />
          {t('I agree to the transfer of data')}
        </label>
        {errors.Privacy.state && (
          <p className="help is-danger">
            {t('validation.' + errors.Privacy.message)}
          </p>
        )}
        {/* SUBMIT BUTTON */}
        {CampaignOver ? (
          <span className="campaign-over">{t('campaign over')}</span>
        ) : (
          <button type="submit" className="btn black">
            {sentenceCase(t('participate'))}
          </button>
        )}
        <div className="register-notice">
          <p>
            {t('data collection 1')}
            <Link to="/rules" /*target="_blank"*/>
              {t('data collection 2')}
            </Link>
          </p>
          <p>{t('data collection 3')}</p>
          {/* <span className="block">{t('Keep the campaign packaging')}</span> */}
          <span>
            <span className="is-block">
              {t('campaign-period.line-1') + ': ' + t('campaign-period.line-2')}
            </span>
            {/* <span className="is-block">{t('campaign-period.line-2')}</span> */}
          </span>
          <span className="campaign-number-span">
            {sentenceCase(t('campaign-period.line-3'))}
          </span>
        </div>
      </form>
      {/* <img
        src={PrizesImg}
        className="prizes hidden-mobile"
        alt="sony speaker and a box of chips"
      /> */}
    </div>
  );
};

export default Register;
