import React, { ReactElement } from 'react';
import './Rules.scss';

const Lv: React.FC = (): ReactElement => {
  return (
    <div className="Lv">
      <ol>
        <li>
          Patērētāju kampaņa “Lay's Flavours 2025” ir akcija Latvijā, turpmāk
          tekstā “Akcija”, kuru organizē “Lay's” čipsu izplatītājs Latvijā – SIA
          “MV GROUP Distribution LV“, turpmāk tekstā “Kampaņas organizētājs”,
          adrese: Medus iela 7, Rīga, LV-1048, vienotais reģistrācijas numurs
          40003787568. Akcijas norisi nodrošina Kampaņas organizētāja pilnvarotā
          persona – SIA “Sorbum LV”, vienotais reģistrācijas numurs 40103605552,
          adrese : Jūrkalnes iela 15/25, Rīga.
        </li>
        <li>Akcijas teritorija ir Latvijas Republika.</li>
        <li>Akcijā var piedalīties ikviena persona bez vecuma ierobežojuma.</li>
        <li>
          Akcija norisinās no 2025.gada. 20.janvāra līdz 2025.gada 13.aprīlim
          (ieskaitot).
        </li>
        <li>
          Lai piedalītos Akcijā preces nav jāiegādājas, bet tikai jānobalso par
          savu iecienītāko čipsu garšu no piedāvātajiem Akcijas variantiem un
          jāreģistrējas vietnē web:{' '}
          <a href="http://www.laysloterija.lv">www.laysloterija.lv</a> , ņemot
          vērā sekojošo:{' '}
          <ol>
            <li>Balsot var vienu reizi;</li>
            <li>
              Lai piedalītos Akcijā jāreģistrējas norādītajā mājaslapā norādot
              balsotāja - Akcijas dalībnieka vārdu, uzvārdu, tālruņa numuru un
              e-pasta adresi;
            </li>
            <li>
              Akcijas dalībnieks ir atbildīgs par pareizu personas datu
              iesniegšanu, kā arī par pieļautajām kļūdām tos norādot
              reģistrācijas procesā;
            </li>
            <li>
              Reģistrācija vietnē web:{' '}
              <a href="http://www.laysloterija.lv">www.laysloterija.lv</a> ir
              bez maksas;
            </li>
            <li>
              Kampaņas organizētājs nav atbildīgs par Akcijas dalībnieka
              izdevumiem balsojot un piedaloties Akcijā, un neatlīdzina tos.
            </li>
          </ol>
        </li>
        <li>
          Akcijas Balvu fonds un Balvu ieguvēju, no Akcijas dalībnieku vidus,
          noteikšanas datumi:{' '}
          <ol>
            <li>
              Balvu fonda vērtība ir 1260,- EUR (viens tūkstotis divi simti
              sešdesmit euro);
            </li>
            <li>
              Kampaņas organizētājs no Akcijas dalībnieku vidus pēc nejaušības
              principa noteiks 60 (sešdesmit) “Lay's” čipsu kastu 120 g vai 130
              g iepakojumos (čipsu kastes vērtība 21,- EUR) ieguvējus. Balvu
              ieguvēji attiecīgi saņems tādas garšas “Lay's” kartupeļu čipsu
              kasti, par kādu garšu dalībnieks nobalsoja piedaloties Akcijā.
              Vienā čipsu kastē ir 21 (divdesmit viens) čipsu iepakojums,
              attiecīgi, 130 g “Lay's” Roasted Garlic & Herbs vai 130 g “Lay's”
              Mac & Cheese, vai 120 g “Lay's” MAX Pulled Beef, tātad katrs
              Akcijas Balvas ieguvējs kā balvu saņems vienu čipsu kasti vai 21
              čipsu paku;
            </li>
            <li>
              Akcijas balvu ieguvēji tiks noteikti no 2025.gada 27.janvāra līdz
              2025.gadam 14.aprīlim, katru pirmdienu plkst.12:00. Katrā Akcijas
              balvu noteikšanas reizē tiks noteikti 5 (pieci) Akcijas
              dalībnieki, kuri iegūs Akcijas balvu, vienu “Lay's” čipsu kasti.
              Uz Akcijas balvu pretendēs visi Akcijas dalībnieki, kuri līdz
              katram Akcijas balvu ieguvēju noteikšanas brīdim būs
              reģistrējušies Akcijai. Katrs Akcijas dalībnieks var tikt noteikts
              par Akcijas balvu ieguvēju tikai vienreiz.
            </li>
          </ol>
        </li>
        <li>
          Akcijas dalībnieku reģistrāciju un Akcijas balvu ieguvēju noteikšanu
          izlozes kārtībā nodrošinās Akcijas organizētāja pilnvarotais pārstāvis
          SIA “Sorbum LV”, kurš arī sazināsies ar Akcijas balvu ieguvējiem.
        </li>
        <li>
          Personas datu apstrādes kārtība:{' '}
          <ol>
            <li>
              Akcijas laikā dalībnieku iesniegtos personas datus uzglabā un
              apstrādā SIA “Sorbum LV” tikai Akcijas realizācijas nolūkos,
              ievērojot spēkā esošos normatīvos aktus un tiesību normas;
            </li>
            <li>
              Reģistrējoties Akcijai, Akcijas dalībnieks piekrīt, ka SIA “Sorbum
              LV” apstrādās iesniegtos personas datus šādiem mērķiem:{' '}
              <ol>
                <li>apkopot Akcijas rezultātus;</li>
                <li>identificēt un informēt Akcijas balvu ieguvējus;</li>
                <li>
                  publicēt Akcijas balvu ieguvēju vārdu un uzvārdu Akcijas
                  mājaslapā un sociālajos tīklos.
                </li>
              </ol>
            </li>
            <li>
              Akcijas dalībniekiem ir tiesības saņemt papildus informāciju par
              SIA “Sorbum LV” apstrādātajiem personas datiem. Lai iegūtu
              informāciju pieprasījums adresējams Akcijas organizētājam nosūtot
              uz e-pasta adresi:{' '}
              <a href="mailto:birojs@mvgroup.eu">birojs@mvgroup.eu</a> .
            </li>
            <li>
              Personas datu pārzinis ir SIA “MV GROUP Distribution LV”, adrese:
              Medus iela 7, Rīga, Latvija, LV-1048;
            </li>
            <li>
              Apkopotie personas dati tiks uzglabāti 1 (vienu) kalendāro mēnesi
              pēc Akcijas beigu datuma vai līdz 2025.gada 14.maijam.
            </li>
          </ol>
        </li>
        <li>
          Akcijas balvu ieguvēji tiks paziņoti ne vēlāk kā attiecīgās Akcijas
          balvu ieguvēju noteikšanas dienas beigās, šo noteikumu 6.3.punktā
          norādītajos datumos, tīmekļa vietnē{' '}
          <a href="http://www.laysloterija.lv">www.laysloterija.lv</a> .
        </li>
        <li>
          Akcijas balvu ieguvēju vārdi būs publiski pieejami Akcijas vietnē web:{' '}
          <a href="http://www.laysloterija.lv">www.laysloterija.lv</a> 2 (divas)
          nedēļas pēc Akcijas beigām, t.i. līdz 2025.gada 27.aprīlim.
        </li>
        <li>
          Ar Akcijas balvu ieguvējiem sazināsies Akcijas organizētāja
          pilnvarotais pārstāvis izmantojot Akcijas dalībnieka reģistrācijas
          anketā norādīto e-pasta adresi vai tālruni, un vienojieties par
          Akcijas balvas saņemšanas laiku.
        </li>
        <li>
          Akcijas balvu ieguvēji iegūto balvu – “Lay’s” čipsu kastes var saņemt
          SIA “Sorbum LV” birojā pēc adreses: Jūrkalnes iela 15/25, Rīga, vai
          citā ar Akcijas organizētāju saskaņotā veidā (piemēram, izmantojot
          kurjeru vai pakomātu), ņemot vērā sekojošo:{' '}
          <ol>
            <li>
              Ja Akcijas balvas ieguvējs neuzrāda personu apliecinošu dokumentu
              vai dokumentos norādītie dati atšķiras no Akcijas reģistrācijas
              anketā norādītajiem, Akcijas balvas ieguvējs nav tiesīgs saņemt
              balvu;
            </li>
            <li>
              Ja Akcijas balvas ieguvējs nav pilngadīgs, viņa balvu tiesību
              normās noteiktajā kārtībā var izņemt viņa likumiskais pārstāvis,
              uzrādot Akcijas balvas ieguvēja un viņa pārstāvja personību
              apliecinošu dokumentu (pasi vai personas apliecību).
            </li>
          </ol>
        </li>
        <li>
          Izsniedzot Akcijas balvas ieguvējam Akcijas balvu, Akcijas balvas
          ieguvējs un Akcijas organizētāja pārstāvja SIA “Sorbum LV” pilnvarotā
          persona paraksta Akcijas balvas nodošanas-pieņemšanas aktu. Pēc akta
          parakstīšanas Akcijas balvas ieguvējam nav tiesību izvirzīt nekādas
          pretenzijas saistībā ar Akciju, tai skaitā pret Akcijas organizētāju.
        </li>
        <li>
          Akcijas organizētājs nav atbildīgs par zaudējumiem, kas Akcijas balvas
          ieguvējam radušies balvas saņemšanas un/vai izmantošanas rezultātā.
        </li>
        <li>Akcijas balvas nav apmaināmas pret naudu vai citām balvām.</li>
        <li>
          Papildu izmaksas, kas saistītas ar Akcijas balvu saņemšanu, un kuras
          nav paredzēts segt saskaņā ar šiem noteikumiem, Akcijas balvu
          ieguvējiem netiks atlīdzinātas.
        </li>
        <li>
          Akcijas balvām, kas nav nodotas Akcijas balvu ieguvējiem, no Akcijas
          organizētāja neatkarīgu iemeslu dēļ, vai kuras Akcijas balvu ieguvējs
          nav izņēmis, ja 5 (piecu) darba dienu laikā pēc Akcijas balvas
          ieguvēja noteikšanas dienas nav iespējams sazināties ar attiecīgo
          Akcijas balvas ieguvēju, vai kuras Akcijas balvas ieguvējs nav izņēmis
          ar Akcijas organizētāju saskaņotajā termiņā, tiks saskaņā ar šiem
          noteikumiem atkārtoti izlozētas starp citiem Akcijas dalībniekiem.
        </li>
        <li>
          Akcijas organizētājs nav atbildīgs, ja Akcijas dalībnieki neievēro šos
          noteikumus.
        </li>
        <li>
          Akcijas organizētājs nav atbildīgs, ja Akcijas dalībnieks tiek
          izslēgts no piedalīšanās Akcijā un/vai Akcijas balva viņam netiek
          piešķirta šo Akcijas noteikumu neievērošanas un/vai pārkāpšanas dēļ,
          tai skaitā gadījumos, ja Akcijas dalībnieka iesniegtie vai norādītie
          dati reģistrējoties Akcijai ir nepatiesi vai neprecīzi.
        </li>
      </ol>
    </div>
  );
};

export default Lv;
