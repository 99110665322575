import React, { ReactElement, useState } from 'react';
import './Navigation.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { sentenceCase } from 'converting-case';

//Component imports
import LogoLink from '../logo-link/LogoLink';

//Asset imports
// import Circles from '../../assets/images/circles.png';
import Chips1 from '../../assets/images/chips_3.png';
import Chips2 from '../../assets/images/chips_5.png';
import ChipsBag from '../../assets/images/chips_but_bag.png';
import Chips1Mobile from '../../assets/images/chips_3.png';
import ChipsBowl from '../../assets/images/bowl.png';

const Navigation: React.FC = (): ReactElement => {
  const [open, toggleOpen] = useState(false);

  const [animation, setAnimation] = useState('nav-slide-in-right');

  const toggleElementOpen = () => {
    setAnimation(open ? 'nav-slide-out-right' : 'nav-slide-in-right');

    setTimeout(
      () => {
        toggleOpen(!open);
      },
      open ? 1100 : 0
    );
  };
  const { t } = useTranslation();
  return (
    <div className="Navigation">
      {/* if nav open=true render the menu */}
      {open && (
        <div className={'nav-menu columns is-mobile is-centered ' + animation}>
          <LogoLink />

          <ul className="column is-narrow">
            <li>
              <Link to="/" onClick={() => toggleElementOpen()}>
                {sentenceCase(t('home'))}
              </Link>
            </li>
            <li>
              <Link to="/" onClick={() => toggleElementOpen()}>
                {sentenceCase(t('register'))}
              </Link>
            </li>
            <li>
              <Link to="/winners" onClick={() => toggleElementOpen()}>
                {sentenceCase(t('winners'))}
              </Link>
            </li>
            <li>
              <Link to="/rules" onClick={() => toggleElementOpen()}>
                {sentenceCase(t('rules'))}
              </Link>
            </li>
          </ul>
        </div>
      )}
      <button onClick={() => toggleElementOpen()} className="nav-button">
        <FontAwesomeIcon icon={open ? faTimes : faBars} />
      </button>
    </div>
  );
};

export default Navigation;
